// import
import Dashboard from "views/Dashboard/Dashboard.js";
import Tables from "views/Dashboard/Tables.js";
import Billing from "views/Dashboard/Billing.js";
import RTLPage from "views/RTL/RTLPage.js";
import Profile from "views/Dashboard/Profile.js";
import SignIn from "views/Pages/SignIn.js";
import SignUp from "views/Pages/SignUp.js";

import {
  HomeIcon,
  StatsIcon,
  CreditIcon,
  PersonIcon,
  DocumentIcon,
  RocketIcon,
  SupportIcon,
} from "components/Icons/Icons";
import Terms from "views/Dashboard/terms";
import Privacy from "views/Dashboard/Privacy";

var dashRoutes = [
  {
    path: "/dashboard",
    name: "Dashboard",
    rtlName: "لوحة القيادة",
    icon: <HomeIcon color='inherit' />,
    component: Dashboard,
    layout: "/admin",
  },
  {
    path: "/tables",
    name: "Plan Page",
    rtlName: "لوحة القيادة",
    icon: <StatsIcon color='inherit' />,
    component: Tables,
    layout: "/admin",
  },
  {
    path: "/billing",
    name: "Withdrawl Page",
    rtlName: "لوحة القيادة",
    icon: <CreditIcon color='inherit' />,
    component: Billing,
    layout: "/admin",
  },
  {
    path: "/terms",
    name: "Terms & Condition",
    rtlName: "لوحة القيادة",
    icon: <CreditIcon color='inherit' />,
    component: Terms,
    layout: "/admin",
  },
  {
    path: "/privacy",
    name: "Privacy policy",
    rtlName: "لوحة القيادة",
    icon: <CreditIcon color='inherit' />,
    component: Privacy,
    layout: "/admin",
  },
  // {
  //   path: "/rtl-support-page",
  //   name: "RTL",
  //   rtlName: "آرتيإل",
  //   icon: <SupportIcon color='inherit' />,
  //   component: RTLPage,
  //   layout: "/rtl",
  // },
  {
    name: "ACCOUNT PAGES",
    category: "account",
    rtlName: "صفحات",
    state: "pageCollapse",
    views: [
       {
        path: "/profile",
        name: "Profile",
        rtlName: "لوحة القيادة",
        icon: <PersonIcon color='inherit' />,
        secondaryNavbar: true,
        component: Profile,
        layout: "/admin",
      },
       {
    path: "/ShippingandDelivery",
    name: "Shipping and Delivery",
    rtlName: "لوحة القيادة",
    icon: <CreditIcon color='inherit' />,
    component: Privacy,
    layout: "/admin",
  },
  {
    path: "/contactus",
    name: "Contact us",
    rtlName: "لوحة القيادة",
    icon: <CreditIcon color='inherit' />,
    component: Privacy,
    layout: "/admin",
  },
  {
    path: "/CancellationandRefund",
    name: "Cancellation and Refund",
    rtlName: "لوحة القيادة",
    icon: <CreditIcon color='inherit' />,
    component: Privacy,
    layout: "/admin",
  },
      {
        path: "/signin",
        name: "Sign In",
        rtlName: "لوحة القيادة",
        icon: <DocumentIcon color='inherit' />,
        component: SignIn,
        layout: "/auth",
      },
      // {
      //   path: "/signup",
      //   name: "Sign Up",
      //   rtlName: "لوحة القيادة",
      //   icon: <RocketIcon color='inherit' />,
      //   secondaryNavbar: true,
      //   component: SignUp,
      //   layout: "/auth",
      // },
    ],
  },
];
export default dashRoutes;
